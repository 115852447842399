<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action>
        <arrow-left-icon class="arrow-left" @click.prevent="goToDashboard" />
      </template>

      <template v-slot:title>
        <h2>{{ $t("packetSearch.packets") }}</h2>
        <h1>{{ $t("packetSearch.receive-release") }}</h1>
      </template>
    </AppHeader>

    <main>
      <form class="container" @submit.prevent>
        <label for="searchInput" class="search-input-label">
          <span v-if="featureFlags.frontend_packet_search_label_slash">
            {{ $t("packetSearch.passwordAlternative") }}
          </span>
          <span v-else>{{ $t("packetSearch.password") }}</span>
          <span class="mandatory">*</span>
        </label>
        <div class="search-input-wrapper">
          <input
            type="text"
            id="searchInput"
            :value="searchCode"
            @input="handleInput"
            ref="searchForm"
            autocorrect="off"
            autocapitalize="off"
            autocomplete="off"
            spellcheck="false"
            :disabled="searching"
          />
          <button
            v-if="searchCode"
            type="button"
            :class="isMobile && 'shift-left'"
            @click="clearSearchInput"
          >
            <close-icon :size="20" class="flat-icon close-icon" />
          </button>
          <a
            href="#"
            v-if="isMobile && !isPaymentDevice"
            @click.prevent="triggerMobileOCR"
          >
            <camera-icon :size="30" class="flat-icon camera-icon" />
          </a>
        </div>
      </form>
      <div class="text-center" v-if="showSuccess">
        <check-icon :size="160" />
      </div>
      <Errors :errors="errors" :errorMedium="true" />
    </main>
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { debounce, delay, isEmpty } from "lodash";
import ArrowLeftIcon from "vue-material-design-icons/ArrowLeft.vue";
import CameraIcon from "vue-material-design-icons/Camera.vue";
import CheckIcon from "vue-material-design-icons/Check.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import { getParams } from "@/mixins/platform-params.js";
import { sendHeadersToMobile } from "@/mixins/send-headers.js";
import { SERVICE_TYPES } from "@/store/service.type";
import { MOBILE_TYPES } from "@/util/mobile.type";
import AppHeader from "@/components/AppHeader";
import Errors from "@/components/Errors";
import Spinner from "@/components/Spinner";

const SEARCH_INPUT_MAX_LENGTH = 12;

export default {
  name: "PacketSearch",
  mixins: [getParams, sendHeadersToMobile],
  components: {
    Errors,
    AppHeader,
    ArrowLeftIcon,
    CameraIcon,
    CheckIcon,
    CloseIcon,
    Spinner
  },
  data() {
    return {
      searching: false,
      showSuccess: false,
      SERVICE_TYPES
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    next();
  },

  computed: {
    ...mapState(["errors", "loading", "featureFlags"]),
    ...mapState("packet", ["packet", "sender", "serviceType"]),
    ...mapGetters("auth", ["packetCheckEnabled"]),

    searchCode: {
      get: function() {
        return this.$store.state.packet.searchCode;
      },

      set: function(searchCode) {
        return this.setSearchCode(searchCode.toUpperCase());
      }
    }
  },

  watch: {
    searchCode() {
      this.callDebounce();
    }
  },

  created() {
    this.callDebounce = debounce(this.onType, 1000);
  },

  mounted() {
    delay(() => {
      this.$refs.searchForm.focus();
    }, 500);
  },

  methods: {
    ...mapActions("packet", [
      "searchPassword",
      "getPacketDetail",
      "deliverPacket",
      "receivePacket",
      "receiveC2CPacket",
      "getReturnPackets",
      "getReturnShipment"
    ]),

    ...mapMutations(["setErrors", "clearErrors"]),
    ...mapMutations("packet", ["setSearchCode", "eshopName"]),

    goToDashboard() {
      this.$router.push({
        name: "dashboard",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    },

    delayRoutingToPath(path) {
      this.showSuccess = true;

      delay(() => {
        this.showSuccess = false;
        this.$router.push({
          name: path,
          params: { code: this.searchCode },
          query: {
            platform: this.platform,
            device: this.device
          }
        });
      }, 1000);
    },

    handleInput({ target }) {
      // Remove all whitespaces
      let value = target.value.replace(/\s/g, "");
      const currentLength = value.length;

      if (currentLength > SEARCH_INPUT_MAX_LENGTH) {
        value = value.slice(0, SEARCH_INPUT_MAX_LENGTH);
      }

      // Update the value inside the input and the state
      target.value = this.searchCode = value;
    },

    clearSearchInput() {
      this.searchCode = "";
      this.$refs.searchForm.focus();
    },

    toggleSearchInput(force) {
      this.searching = !force;
      if (!force) return;

      this.$nextTick(() => {
        this.$refs.searchForm.focus();
      });
    },

    triggerMobileOCR() {
      if (this.platform == MOBILE_TYPES.ANDROID) {
        window.PacketaPPA?.openOCR?.();
      }

      this.$refs.searchForm.focus();
    },

    async onType() {
      this.clearErrors();

      if (this.searchCode?.length < 5) return;

      this.toggleSearchInput(false);

      const response = await this.searchPassword(this.searchCode);

      if (!response) {
        this.toggleSearchInput(true);

        return;
      }

      switch (this.serviceType) {
        case SERVICE_TYPES.DELIVERY:
          this.handleDelivery();
          break;
        case SERVICE_TYPES.RECEIVE:
        case SERVICE_TYPES.C2C_CONSIGN:
          this.handleReceive();
          break;
        case SERVICE_TYPES.RETURN_PACKETS_FOR_CLIENT:
          this.handleReturn();
          break;
        case SERVICE_TYPES.RECLAMATION_ASSISTANT:
          this.handleReturnShipment();
          break;
        default:
          this.handleUnsupportedType();
          break;
      }
    },

    // DELIVERY
    async handleDelivery() {
      if (isEmpty(this.packet)) return;

      if (this.isMobile) {
        this.sendHeadersToMobile(
          this.packet.barcode,
          this.searchCode,
          this.readyToPick
        );

        if (this.packetCheckEnabled) {
          this.delayRoutingToPath("packet-check");

          return;
        }
      }

      if (this.packet.adult_content) {
        this.delayRoutingToPath("packet-age-check");

        return;
      }

      const response = await this.deliverPacket({
        password: this.searchCode,
        barcode: this.packet.barcode
      });

      if (!response) {
        this.toggleSearchInput(true);

        return;
      }

      this.delayRoutingToPath("packet-detail");
    },

    // RECEIVE
    async handleReceive() {
      const params = {
        barcode: this.packet.barcode,
        password: this.searchCode
      };

      const response =
        this.serviceType === SERVICE_TYPES.C2C_CONSIGN
          ? await this.receiveC2CPacket(params)
          : await this.receivePacket(params);

      if (!response) {
        this.toggleSearchInput(true);

        return;
      }

      this.delayRoutingToPath("packet-receive");
    },

    // RETURNS
    async handleReturn() {
      if (!this.sender) {
        this.setErrors([
          {
            code: this.serviceType,
            text: this.$t("errorMessages.password-sender-missing")
          }
        ]);

        this.toggleSearchInput(true);

        return;
      }

      const response = await this.getReturnPackets(this.searchCode);

      if (!response) {
        this.toggleSearchInput(true);

        return;
      }

      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.sendSender(this.sender);
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.sendSender.postMessage({
            sender: this.sender
          });
        }
      }

      this.delayRoutingToPath("packets-return");
    },

    // RETURN SHIPMENT
    async handleReturnShipment() {
      if (!this.eshopName) return;

      this.delayRoutingToPath("packet-return-shipment");
    },

    // UNSUPPORTED
    handleUnsupportedType() {
      this.setErrors([
        {
          code: this.serviceType,
          text: this.$t("packetSearch.unsupported_code_error")
        }
      ]);

      this.toggleSearchInput(true);
    }
  }
};
</script>

<style lang="scss" scoped>
main {
  padding: 0 40px;

  .check-icon {
    color: $green;
  }
}

.search-input {
  &-label {
    display: inline-block;
    margin: 23px 0 0;
  }

  &-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    input {
      padding: 12px 9px;
      font-size: 1.375rem !important;
      line-height: 1;
      text-transform: uppercase;
    }

    button,
    a {
      position: absolute;
      inset: 0 0 0 auto;
      display: flex;
      padding-inline: 0.75em;
      align-items: center;

      .flat-icon {
        line-height: 0;
      }

      .close-icon {
        opacity: 0.4;
      }

      .camera-icon {
        color: $rust-red;
      }
    }

    button {
      background: none;
      border: 0;

      &.shift-left {
        right: 3rem;
      }

      &:hover {
        cursor: pointer;

        .close-icon {
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
